<template>
  <div class="commodityManagement-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
  let validOrderId = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("订单号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validItemsName = (rule, value, callback) => {
      if (value == "" || value == undefined) {
          callback();
      } else {
          //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
          let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
          if (!reg.test(value)) {
              callback(new Error("商品名称格式不对或超过32个字符"));
          } else {
              callback();
          }
      }
  };
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        opt: {
          title: "售货订单列表",
          search: [
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
                change(data) {
                    _this.getStoreList(data)
                }
              }
            },
            {
              key: "groupId",
              label: "按门店",
              type: "remoteSearchById",
              opt: {
                list: []
              }
            },
            {
              label: "商品名称",
              key: 'itemsName',
              rules: [{
                  validator: validItemsName,
                  trigger: ['blur']
              }]
            },
            {
              key: "orderId",
              label: "主订单号",
              maxlength: 32,
              rules: [{
                validator: validOrderId,
                trigger: ['blur']
              }]
            },
            // {
            //   key: "counterName",
            //   label: "货柜名称"
            // },
            {
              key: "payMethod",
              label: "支付方式",
              type: 'select',
              opt: {
                list: [
                  {
                    value: 1,
                    label: '微信支付'
                  },
                  {
                    value: 6,
                    label: '刷卡支付'
                  },
                  {
                    value: 4,
                    label: '会员余额支付'
                  }
                ],
              }
            },
            {
              key: "date",
              label: "下单日期",
              type: "data-range"
            },
            {
              type: 'btnGroup',
              key: "btnGroup",
              buts: [{
                  type: "primary",
                  label: "今日",
                  timeType: 'day'
                },
                {
                  type: "primary",
                  label: "最近一周",
                  timeType: 'lastWeek'
                },
                {
                  type: "primary",
                  label: "本月",
                  timeType: 'month'
                },
                {
                  type: "primary",
                  label: "上月",
                  timeType: 'lastMonth'
                }
              ]
            }
          ],
          columns: [{
              label: "主订单号",
              key: "id",
              align: "center",
              on: row => {
                _this.$router.push({
                  path: "/main/salesOrder/detail",
                  query: {
                    id: row.id,
                  }
                })
              }
            },
            {
              label: "商品名称",
              key: "itemName",
              align: "center",
              opt: {
                  isUserPopover: true
              }
            },
            {
              label: "门店/货柜",
              key: "groupName",
              align: "center"
            }, 
            {
              label: "商家名称",
              key: "merchantName",
              align: "center"
            }, 
            {
              label: "客户/消费者",
              key: "receiverName",
              align: "center"
            },
            {
              label: "数量",
              key: "buyCounts",
              align: "center"
            },
            {
              label: "订单总金额",
              key: "totalAmountStr",
              align: "center"
            },
            {
              label: "支付方式",
              key: "payMethodStr",
              align: "center"
            },
            {
              label: "下单时间",
              key: "createdTime",
              align: "center"
            },
            // {
            //   label: "操作",
            //   key: "action",
            //   type: "action-but",
            //   fixed: "right",
            //   width: 100,
            //   align: "center",
            //   opt: {
            //     list: [{
            //       label: "详情",
            //       on(row) {
            //         _this.$router.push({
            //           path: "/main/salesOrder/detail",
            //           query: {
            //             id: row.id,
            //             type: row.counterType, //L2k
            //             orderItemsRespList: row.orderItemsRespList
            //           }
            //         })
            //       }
            //     }]
            //   }
            // }
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/mall-service/orders/export",
            listName: '售货订单'
          }],
        },
        merchantFlag: false,
      };
    },
    created() {
      console.log("salesOrder-list created!!");

    },
    async activated() {
      console.log("salesOrder-list activated!!");
      this.opt.search.forEach(item => {
        if (item.key == "btnGroup") {
          item.buts.forEach(it => {
            it.type = "primary";
          })
        }
      })
      try {
        // let groupList = await cabinetUtil.getStoreList();
        // this.opt.search.forEach(item => {
        //   if (item.key == 'groupId') {
        //     item.opt.list = groupList;
        //   }
        // })
        this.merchantFlag = this.getLoginType('loginType');
        if (this.merchantFlag) {
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm,
          createTimeBegin: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          createTimeEnd: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") :
            null,
        };
        this.post("mall-service/orders/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach(item => {
            if (item.groupName) {
              if (item.region) {
                item.groupName = item.groupName + '-' + item.region + item.regionNumber;
              } else {
                item.groupName = item.groupName;
              }

            } else {
              item.groupName = '--';
            }
            if (item.payMethod == 1) {
              item.payMethodStr = "微信支付";
            } 
            // else if (item.payMethod == 5) {
            //   item.payMethodStr = "微信支付（使用优惠券）";
            // }
            else if (item.payMethod == 4) {
              item.payMethodStr = "会员余额支付";
            }
            else if (item.payMethod == 6) {
              item.payMethodStr = "刷卡支付";
            }
            // else if (item.payMethod == 7) {
            //   item.payMethodStr = "通联支付";
            // }
            item.totalAmountStr = item.totalAmount ? item.totalAmount.toFixed(2) : '--';
            item.receiverName = item.receiverName ? item.receiverName : "--";
     
            item.merchantName = item.merchantName ? item.merchantName : '--';
          });
          opt.cb(res.data);
        });
        if(opt.searchForm.merchantId) {
            this.getStoreList(opt.searchForm.merchantId);
        }else{
            this.getStoreList();
        }
      },
      //获取门店列表
      getStoreList(data) {
          let dto = {
              currentPage: 1,
              size: 99999,
          };
          if(data) {
              dto.merchantId = data
          }
          this.post("/mall-service/v1/counterStores/getAllList", dto, {
              isUseResponse: true,
          }).then(res => {
              let storeList = res.data.data;
              storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
              storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
              this.opt.search.forEach(item => {
                  if (item.key == 'groupId') {
                  item.opt.list = storeList;
                  }
              })
          });
      },
    }
  };
</script>
<style lang="scss" scoped>
  /deep/.el-table td:last-child {
    padding-right: 16px
  }
</style>